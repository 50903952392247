import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Theme } from '@mui/material';

export default function Select(theme: Theme) {
	return {
		MuiSelect: {
			defaultProps: {
				IconComponent: ExpandMoreRoundedIcon,
			},

			styleOverrides: {
				root: {},
				icon: {
					right: 15,
					color: theme.palette.common.black,
				},
			},
		},
	};
}
