import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Radio(theme: Theme) {
	return {
		MuiRadio: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
				},
			},
		},
	};
}
