import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
	return {
		MuiMenu: {
			styleOverrides: {
				list: {
					padding: theme.spacing(0),
				},
			},
		},

		MuiMenuItem: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${theme.palette.common.black}`,
					padding: theme.spacing(1.5, 6, 1.5, 2),

					'&:last-child': {
						borderBottomWidth: 0,
					},

					'&.Mui-selected': {
						backgroundColor: theme.palette.action.selected,
						'&:hover': {
							backgroundColor: theme.palette.action.hover,
						},
					},
				},
			},
		},
	};
}
