import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Container(theme: Theme) {
	return {
		MuiContainer: {
			styleOverrides: {
				root: {
					paddingLeft: theme.spacing(3),
					paddingRight: theme.spacing(3),

					'@media (min-width: 600px)': {
						paddingLeft: theme.spacing(5),
						paddingRight: theme.spacing(5),
					},
				},
				disableGutters: {
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
		},
	};
}
