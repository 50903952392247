import { Theme } from '@mui/material';

export default function Paper(theme: Theme) {
	return {
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			variants: [
				{
					props: { color: 'dark' },
					style: {
						backgroundColor: '#333333',
						// color: theme.palette.common.white,
					},
				},
			],

			styleOverrides: {
				root: {
					backgroundImage: 'none',
				},
			},
		},
	};
}
