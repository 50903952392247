import { pxToRem } from '~/utils/common';
import * as React from 'react';

export function responsiveFontSizes({
	xs,
	sm,
	md,
	lg,
	xl,
}: {
	xs: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
}) {
	return {
		fontSize: pxToRem(xs),
		'@media (min-width:600px)': {
			fontSize: pxToRem(sm ?? xs),
		},
		'@media (min-width:960px)': {
			fontSize: pxToRem(md ?? sm ?? xs),
		},
		'@media (min-width:1280px)': {
			fontSize: pxToRem(lg ?? md ?? sm ?? xs),
		},
		'@media (min-width:1680px)': {
			fontSize: pxToRem(xl ?? lg ?? md ?? sm ?? xs),
		},
	};
}

const FONT_PRIMARY = 'iqos-sans, Helvetica, Arial, sans-serif'; // Google Font

const typography = {
	fontFamily: FONT_PRIMARY,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	fontWeightBold: 700,
	fontWeightBlack: 900,
	h1: {
		fontWeight: 700,
		lineHeight: 80 / 64,
		...responsiveFontSizes({ xs: 28, sm: 32, md: 36, lg: 40 }),
	},
	h2: {
		fontWeight: 700,
		lineHeight: 64 / 48,
		...responsiveFontSizes({ xs: 23, sm: 24, md: 28, lg: 30 }),
	},
	h3: {
		fontWeight: 700,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 18, sm: 19, md: 21, lg: 26 }),
	},
	h4: {
		fontWeight: 700,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 18, sm: 19, md: 20, lg: 20 }),
	},
	h5: {
		fontWeight: 700,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 17, sm: 17, md: 18, lg: 18 }),
	},
	h6: {
		fontWeight: 700,
		lineHeight: 28 / 18,
		...responsiveFontSizes({ xs: 17, sm: 17, md: 17, lg: 17 }),
	},
	subtitle1: {
		fontWeight: 600,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		fontFamily: FONT_PRIMARY,
	},
	subtitle2: {
		fontWeight: 600,
		lineHeight: 22 / 14,
		fontSize: pxToRem(14),
		fontFamily: FONT_PRIMARY,
	},
	body1: {
		lineHeight: 1.5,
		fontFamily: FONT_PRIMARY,
		...responsiveFontSizes({ xs: 14, sm: 15, md: 15, lg: 18 }),
	},
	body2: {
		lineHeight: 22 / 14,

		...responsiveFontSizes({ xs: 13, sm: 13, md: 14, lg: 16 }),
	},
	caption: {
		lineHeight: 1.5,
		fontSize: pxToRem(12),
		textTransform: 'uppercase',
		fontFamily: FONT_PRIMARY,
	},
	overline: {
		fontWeight: 700,
		lineHeight: 1.5,
		fontSize: pxToRem(12),
		letterSpacing: 1.1,
		textTransform: 'uppercase',
		fontFamily: FONT_PRIMARY,
	},
	button: {
		fontWeight: 700,
		lineHeight: 24 / 14,
		fontSize: pxToRem(20),
		textTransform: 'capitalize',
	},
} as const;

declare module '@mui/material/styles/createTypography' {
	interface FontStyle {
		fontWeightBlack: React.CSSProperties['fontWeight'];
	}
}

export default typography;
