import React, { Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress, CssBaseline } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SnackbarProvider from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/Providers/ErrorBoundary';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { useRoutes, WorkboxContext } from '~/utils/contexts';
import { KioskContext, OfflineContext } from '~/utils/contexts';

import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import { I18nextProvider } from 'react-i18next';
import i18n from '~/utils/i18n';
import { mockLogger } from '@tanstack/react-query/build/lib/__tests__/utils';
import { CDN_LINK } from '~/utils/api/endpoints';

const PRECACHE_ASSETS: string[] = [
	`${CDN_LINK}/IQOSW01-Light.woff2`,
	`${CDN_LINK}/IQOSW01-Light.woff`,
	`${CDN_LINK}/IQOSW05-Regular.woff2`,
	`${CDN_LINK}/IQOSW01-Regular.woff`,
	`${CDN_LINK}/IQOSW10-Bold.woff2`,
	`${CDN_LINK}/IQOSW10-Bold.woff`,
	`${CDN_LINK}/mini-app-flavours-device-iluma.png`,
	`${CDN_LINK}/mini-app-flavours-device-duo.png`,
	`${CDN_LINK}/mini-app-flavours-device-veev.png`,
	'https://storage.googleapis.com/hub2-assets/da99802d-0e0d-4969-ba14-7d788373762c.png',
	'https://storage.googleapis.com/hub2-assets/9591cca2-e9ea-4abd-bcc0-51be45a6d83b.png',
	'https://storage.googleapis.com/hub2-assets/303bec2b-cfb8-4e8e-af2f-717cd9e07397.mp4',
];

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const Fallback = (
	<div
		style={{
			height: '100vh',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'transparent',
		}}
	>
		<CircularProgress />
	</div>
);

const App = () => {
	const theme = useTheme();
	const { router } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense fallback={Fallback}>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	const kiosk = process.env.KIOSK === 'true';

	const [workbox, setWorkbox] = useState<Workbox | null>(null);
	if (!workbox && 'serviceWorker' in navigator) {
		const wb = new Workbox('/sw.js');

		wb.addEventListener('waiting', (event) => {
			wb.addEventListener('controlling', () => {
				// At this point, reloading will ensure that the current
				// tab is loaded under the control of the new service worker.
				// Depending on your web app, you may want to auto-save or
				// persist transient state before triggering the reload.
				window.location.reload();
			});

			wb.messageSkipWaiting();

			window.addEventListener('beforeunload', async () => {
				await wb.messageSkipWaiting();
			});
		});

		wb.register();
		setWorkbox(wb);
		wb.messageSW({ type: '__RP_PRECACHE', payload: PRECACHE_ASSETS });
	}

	// TODO: non hardcoded offline context value if offline context is needed
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<WorkboxContext.Provider value={{ workbox }}>
				<BrowserRouter>
					<Theme>
						<I18nextProvider i18n={i18n}>
							<QueryClientProvider client={queryClient}>
								<OfflineContext.Provider value={{ offline: false }}>
									<KioskContext.Provider value={{ kiosk }}>
										<SnackbarProvider>
											<RoutesProvider routes={rootRoutes}>
												<CssBaseline />
												<App />
											</RoutesProvider>
										</SnackbarProvider>
									</KioskContext.Provider>
								</OfflineContext.Provider>
							</QueryClientProvider>
						</I18nextProvider>
					</Theme>
				</BrowserRouter>
			</WorkboxContext.Provider>
		</ErrorBoundary>
	);
};

// @ts-ignore
window.onload = () => createRoot(document.getElementById('root')).render(<Root />);
