import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const App = lazy(() => import('../pages'));
const Setup = lazy(() => import('../pages/app/setup'));
const Serve = lazy(() => import('../pages/app/serve'));
const Settings = lazy(() => import('../pages/app/settings'));
const Offline = lazy(() => import('../pages/offline'));

const routes: PreRoute[] = [
	{
		path: '/',
		element: <App />,
		children: [
			{
				index: true,
				element: <Setup />,
			},
			{
				path: '/serve',
				element: <Serve />,
			},
			{
				path: '/settings',
				element: <Settings />,
			},
		],
	},
	{
		path: '/offline',
		element: <Offline />,
	},
];

export const navigation = [
	{
		name: 'App',
		path: '/',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	},
	{
		name: 'Setup',
		path: '/setup',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	},
];

export default routes;
