import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { CDN_LINK } from '~/utils/api/endpoints';

const globalStyles = (theme: Theme, assets?: any) => css`
	@import url('https://use.typekit.net/btj3fdv.css');

	@font-face {
		font-family: 'iqos-sans';
		src: url(${assets?.fontIqosSansLightWoff2
					? assets?.fontIqosSansLightWoff2
					: `${CDN_LINK}/IQOSW01-Light.woff2`})
				format('woff2'),
			url(${assets?.fontIqosSansLightWoff
					? assets?.fontIqosSansLightWoff
					: `${CDN_LINK}/IQOSW01-Light.woff`})
				format('woff');
		font-weight: 300;
	}

	@font-face {
		font-family: 'iqos-sans';
		src: url(${assets?.fontIqosSansRegularWoff2
					? assets?.fontIqosSansRegularWoff2
					: `${CDN_LINK}/IQOSW05-Regular.woff2`})
				format('woff2'),
			url(${assets?.fontIqosSansRegularWoff
					? assets?.fontIqosSansRegularWoff
					: `${CDN_LINK}/IQOSW01-Regular.woff`})
				format('woff');
		font-weight: 400;
	}

	@font-face {
		font-family: 'iqos-sans';
		src: url(${assets?.fontIqosSansBoldWoff2
					? assets?.fontIqosSansBoldWoff2
					: `${CDN_LINK}/IQOSW10-Bold.woff2`})
				format('woff2'),
			url(${assets?.fontIqosSansBoldWoff
					? assets?.fontIqosSansBoldWoff
					: `${CDN_LINK}/IQOSW10-Bold.woff`})
				format('woff');
		font-weight: 700;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html,
	body {
		-webkit-overflow-scrolling: touch;
		background-color: transparent;
	}

	body {
		overflow: unset;
		background-color: transparent;
	}

	html,
	body,
	#root {
		height: 100%;
		background-color: transparent;
	}

	div[role='group'][tabindex] {
		height: 100%;
	}

	button {
		outline: none;
		border: none;
		font-family: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		font-family: ${theme.typography.body1.fontFamily};
		background-color: transparent;
	}

	//.wrap-children {
	//	display: flex;
	//	flex-wrap: wrap;
	//}
	//
	//.next-is-wrapped,
	//.next-is-wrapped ~ * {
	//	// [flex-grow vs flex](https://github.com/angular/material/issues/2632)
	//	flex: auto;
	//}
`;

export default globalStyles;
