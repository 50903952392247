import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
	return {
		MuiInputBase: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						'& svg': { color: theme.palette.text.disabled },
					},
				},

				input: {
					borderRadius: 25,

					'&::placeholder': {
						opacity: 1,
						color: theme.palette.text.disabled,
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					'&:before': {
						borderBottomColor: theme.palette.grey[500_56],
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.grey[500_12],
					'&:hover': {
						backgroundColor: theme.palette.grey[500_16],
					},
					'&.Mui-focused': {
						backgroundColor: theme.palette.action.focus,
					},
					'&.Mui-disabled': {
						backgroundColor: theme.palette.action.disabledBackground,
					},
				},
				underline: {
					'&:before': {
						borderBottomColor: theme.palette.grey[500_56],
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 5,
					color:
						theme.palette.mode === 'dark'
							? theme.palette.common.white
							: theme.palette.common.black,

					'& .MuiOutlinedInput-notchedOutline': {
						borderColor:
							theme.palette.mode === 'dark'
								? theme.palette.common.white
								: theme.palette.common.black,
						'& legend': {
							fontSize: `1rem`,
						},
					},

					'&.Mui-disabled': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.action.disabledBackground,
						},
					},

					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor:
								theme.palette.mode === 'dark'
									? theme.palette.common.white
									: theme.palette.common.black,
						},
					},

					svg: {
						color:
							theme.palette.mode === 'dark'
								? theme.palette.common.white
								: theme.palette.common.black,
						right: theme.spacing(1),
					},
				},
				input: {
					padding: theme.spacing(2, 3),
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color:
						theme.palette.mode === 'dark'
							? theme.palette.common.white
							: theme.palette.grey[600],
					transform: `translate(20px, 16px) scale(1)`,

					'&.Mui-focused': {
						color:
							theme.palette.mode === 'dark'
								? theme.palette.common.white
								: theme.palette.common.black,
					},
				},

				shrink: {
					transform: `translate(14px, -9px) scale(0.75)`,
				},
			},
		},
	};
}
