import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '~/locales/en/translations.json';
import ja from '~/locales/ja/translations.json';
import zhCN from '~/locales/zh-cn/translations.json';
import zhTW from '~/locales/zh-tw/translations.json';
import ar from '~/locales/ar/translations.json';
import de from '~/locales/de/translations.json';
import fr from '~/locales/fr/translations.json';

i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	.init(
		{
			lng: 'en',
			fallbackLng: ['en', 'cn', 'tw', 'ar', 'de', 'fr', 'ja'],
			ns: ['translation'],
			defaultNS: 'translation',
			react: { useSuspense: false },
			interpolation: { escapeValue: false },
			resources: {
				en: { translation: en },
				ja: { translation: ja },
				cn: { translation: zhCN },
				tw: { translation: zhTW },
				ar: { translation: ar },
				de: { translation: de },
				fr: { translation: fr },
			},
			supportedLngs: ['en', 'cn', 'tw', 'ar', 'de', 'fr', 'ja'],
		},
		// We must provide a function as second parameter, otherwise i18next errors
		(err, t) => {
			if (err) return console.log(err);
		}
	);

export default i18next;
