const breakpoints = {
	values: {
		xs: 0,
		sm: 690,
		md: 960,
		lg: 1300,
		xl: 1800,
	},
};

export default breakpoints;
